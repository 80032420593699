import React from "react"

import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <LayoutNew>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Coax Ports – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <section className="px-4 py-8 mx-auto font-light prose prose-lg">
      <h1>Example Coax Ports</h1>
      <p>
        Feel free to take a look around your home to make sure you have a coax
        port.
      </p>
      <StaticImage
        className="max-w-lg mx-auto"
        layout="constrained"
        src="../content/PurpleCowCoaxLine.png"
        alt="A coax cable"
      />

      <div className="text-xl">Or</div>
      <StaticImage
        className="max-w-lg mx-auto"
        layout="constrained"
        src="../content/PurpleCowCoaxPort.png"
        alt="A coax port in an outler"
      />
    </section>
  </LayoutNew>
)

export default NotFoundPage
